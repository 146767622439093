<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('administration.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.title.label')"
                        :label-for="'title.' + language.code"
                      >
                        <b-form-input
                          :id="'title.' + language.code"
                          v-model="item.title[language.code]"
                          :state="errors && errors['title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                          {{ errors['title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_h1.label')"
                        :label-for="'meta_h1.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_h1.' + language.code"
                          v-model="item.meta_h1[language.code]"
                          :state="errors && errors['meta_h1.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                          {{ errors['meta_h1.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_title.label')"
                        :label-for="'meta_title.' + language.code"
                      >
                        <b-form-input
                          :id="'meta_title.' + language.code"
                          v-model="item.meta_title[language.code]"
                          :state="errors && errors['meta_title.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                          {{ errors['meta_title.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_description.label')"
                        :label-for="'meta_description.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_description.' + language.code"
                          v-model="item.meta_description[language.code]"
                          :state="errors && errors['meta_description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                          {{ errors['meta_description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.meta_keywords.label')"
                        :label-for="'meta_keywords.' + language.code"
                      >
                        <b-form-textarea
                          :id="'meta_keywords.' + language.code"
                          v-model="item.meta_keywords[language.code]"
                          :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                          {{ errors['meta_keywords.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.description.label')"
                        :label-for="'description.' + language.code"
                        :state="errors && errors['description.' + language.code] ? false : null"
                      >
                        <quill-editor
                          :id="'description.' + language.code"
                          v-model="item.description[language.code]"
                          :options="editorOptions"
                          :state="errors && errors['description.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                          {{ errors['description.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab
              :title="$t('administration.products.tabs.data.label')"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.type.label')"
                    label-for="template_type"
                    :state="errors && errors.template_type ? false : null"
                  >
                    <v-select
                      id="template_type"
                      v-model="item.template_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="template_type"
                      @input="changeType"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.template_type">
                      {{ errors.template_type[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="item.template_type === 'about_us'"
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.photo.label')"
                    :state="errors && errors.photo ? false : null"
                  >
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'photo')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="photo"
                          type="page_photo"
                          :multiple="false"
                          @fm-selected="selectedPhoto"
                        />

                        <!-- reset -->
                        <b-button
                          v-if="item.photo"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.photo = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-form-group>

                  <b-form-invalid-feedback v-if="errors && errors.photo">
                    {{ errors.photo[0] }}
                  </b-form-invalid-feedback>
                </b-col>
                <b-col
                  v-if="item.template_type === 'list'"
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.attribute_id.label')"
                    :label-for="'item.attribute_id'"
                    :state="errors && errors.attribute_id ? false : null"
                  >
                    <v-select
                      :id="'item.attribute_id'"
                      v-model="item.attribute_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="attributesOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.attribute_id">
                      {{ errors.attribute_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('administration.navigation.extensions')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.top_extension_id.label')"
                    label-for="top_extension_id"
                    :state="errors && errors.top_extension_id ? false : null"
                  >
                    <v-select
                      id="top_extension_id"
                      v-model="item.top_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="top_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.top_extension_id">
                      {{ errors.top_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.bottom_extension_id.label')"
                    label-for="bottom_extension_id"
                    :state="errors && errors.bottom_extension_id ? false : null"
                  >
                    <v-select
                      id="bottom_extension_id"
                      v-model="item.bottom_extension_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="extensionOptions"
                      :reduce="val => val.value"
                      input-id="bottom_extension_id"
                      :searchable="true"
                      @search="searchExtension"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.bottom_extension_id">
                      {{ errors.bottom_extension_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.template_type === 'listNO'"
              :title="$t('administration.pages.tabs.brands.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(brand, index) in item.brands"
                      :id="'brand' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'brands.' + index + '.title.' + language.code"
                        >
                          <b-form-input
                            :id="'brands.' + index + '.title.' + language.code"
                            v-model="brand.title[language.code]"
                            :state="errors && errors['brands.' + index + '.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['brands.' + index + '.title.' + language.code]">
                            {{ errors['brands.' + index + '.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.href.label')"
                          :label-for="'brands.' + index + '.href.' + language.code"
                        >
                          <b-form-input
                            :id="'brands.' + index + '.href.' + language.code"
                            v-model="brand.href[language.code]"
                            :state="errors && errors['brands.' + index + '.href.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['brands.' + index + '.href.' + language.code]">
                            {{ errors['brands.' + index + '.href.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeBrand(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addBrand"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.brands"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.brands[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.template_type === 'faq' || item.template_type === 'delivery_and_payment'"
              :title="$t('administration.pages.tabs.faq.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(faq, index) in item.faq"
                      :id="'faq' + index + '.group.' + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'faq.' + index + '.group.title.' + language.code"
                        >
                          <b-form-input
                            :id="'faq.' + index + '.group.title.' + language.code"
                            v-model="faq.group.title[language.code]"
                            :state="errors && errors['faq.' + index + '.group.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.group.title.' + language.code]">
                            {{ errors['faq.' + index + '.group.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeFaq(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>

                      <div class="repeater-form">
                        <b-col cols="12">
                          <b-row
                            v-for="(faqItem, itemIndex) in item.faq[index].items"
                            :id="'faq' + index + '.items.' + itemIndex + '.' + language.code"
                            :key="itemIndex"
                            ref="row"
                          >
                            <b-col
                              cols="12"
                              md="4"
                            >
                              <b-form-group
                                :label="$t('form.question.label')"
                                :label-for="'faq.' + index + '.items.' + itemIndex + '.question.' + language.code"
                              >
                                <b-form-input
                                  :id="'faq.' + index + '.items.' + itemIndex + '.question.' + language.code"
                                  v-model="faqItem.question[language.code]"
                                  :state="errors && errors['faq.' + index + '.items.' + itemIndex + '.question.' + language.code] ? false : null"
                                />
                                <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.items.' + itemIndex + '.question.' + language.code]">
                                  {{ errors['faq.' + index + '.items.' + itemIndex + '.question.' + language.code][0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </b-col>

                            <b-col
                              cols="12"
                              md="6"
                            >
                              <b-form-group
                                :label="$t('form.answer.label')"
                                :label-for="'faq.' + index + '.items.' + itemIndex + '.answer.' + language.code"
                                :state="errors && errors['faq.' + index + '.items.' + itemIndex + '.answer.' + language.code] ? false : null"
                              >
                                <quill-editor
                                  :id="'faq.' + index + '.items.' + itemIndex + '.answer.' + language.code"
                                  v-model="faqItem.answer[language.code]"
                                  :options="editorOptions"
                                  :state="errors && errors['faq.' + index + '.items.' + itemIndex + '.answer.' + language.code] ? false : null"
                                />
                                <b-form-invalid-feedback v-if="errors && errors['faq.' + index + '.items.' + itemIndex + '.answer.' + language.code]">
                                  {{ errors['faq.' + index + '.items.' + itemIndex + '.answer.' + language.code][0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </b-col>

                            <b-col
                              cols="12"
                              md="6"
                            >
                              <b-form-group :label="$t('form.avatar.label')">
                                <!-- media -->
                                <b-media no-body>
                                  <b-media-aside>
                                    <b-link>
                                      <b-img
                                        ref="previewEl"
                                        rounded
                                        :src="$options.filters.mediaUrl(faqItem, 'avatar', '110x110')"
                                        height="80"
                                      />
                                    </b-link>
                                    <!--/ avatar -->
                                  </b-media-aside>

                                  <b-media-body class="mt-75 ml-75">
                                    <InitFileManager
                                      field="avatar"
                                      type="page_faq_item_avatar"
                                      :multiple="false"
                                      @fm-selected="selectedFaqItemAvatar($event, index, itemIndex)"
                                    />

                                    <!-- reset -->
                                    <b-button
                                      v-if="faqItem.avatar"
                                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                      variant="outline-secondary"
                                      size="sm"
                                      class="mb-75 mr-75"
                                      @click="faqItem.avatar = null"
                                    >
                                      {{ $t('general.reset') }}
                                    </b-button>
                                    <!--/ reset -->

                                  </b-media-body>
                                </b-media>
                                <!--/ media -->
                              </b-form-group>
                            </b-col>

                            <!-- Remove Button -->
                            <b-col
                              lg="2"
                              md="2"
                              class="mb-50"
                            >
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removeFaqItem(index, itemIndex)"
                              >
                                <feather-icon
                                  icon="XIcon"
                                  class="mr-25"
                                />
                                <span>{{ $t('general.delete') }}</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>

                        <b-col cols="12">
                          <b-row>
                            <b-col
                              cols="12"
                              md="10"
                            >
                              &nbsp;
                            </b-col>
                            <b-col
                              cols="12"
                              md="2"
                            >
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                @click="addFaqItem(index)"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  class="mr-25"
                                />
                                <span>{{ $t('general.add') }}</span>
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>

                        <b-row
                          v-if="errors && errors['faq.' + index + '.items']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['faq.' + index + '.items'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </div>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addFaq"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.faq"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.faq[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.template_type === 'about_us'"
              :title="$t('administration.pages.tabs.informers.label')"
            >
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>

                  <div class="repeater-form">
                    <b-row
                      v-for="(informer, index) in item.informers"
                      :id="'informer' + index + language.code"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.value.label')"
                          :label-for="'informers.' + index + '.value.' + language.code"
                        >
                          <b-form-input
                            :id="'informers.' + index + '.value.' + language.code"
                            v-model="informer.value[language.code]"
                            :state="errors && errors['informers.' + index + '.value.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['informers.' + index + '.value.' + language.code]">
                            {{ errors['informers.' + index + '.value.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.title.label')"
                          :label-for="'informers.' + index + '.title.' + language.code"
                        >
                          <b-form-input
                            :id="'informers.' + index + '.title.' + language.code"
                            v-model="informer.title[language.code]"
                            :state="errors && errors['informers.' + index + '.title.' + language.code] ? false : null"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['informers.' + index + '.title.' + language.code]">
                            {{ errors['informers.' + index + '.title.' + language.code][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="3"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          @click="removeInformer(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addInformer"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.informers"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.informers[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="item.template_type === 'about_us'"
              :title="$t('administration.pages.tabs.sliders.label')"
            >
              <div class="repeater-form">
                <b-row
                  v-for="(slider, index) in item.sliders"
                  :id="'slider' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.type.label')"
                      :label-for="'sliders.' + index + '.type'"
                      :state="errors && errors['sliders.' + index + '.type'] ? false : null"
                    >
                      <v-select
                        :id="'sliders.' + index + '.type'"
                        v-model="slider.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sliderTypeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'sliders.' + index + '.type'"
                        @input="changeSliderType($event, index)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['sliders.' + index + '.type']">
                        {{ errors['sliders.' + index + '.type'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="slider.type === 'video'"
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.video.label')"
                      :label-for="'sliders.' + index + '.video'"
                    >
                      <b-form-input
                        :id="'sliders.' + index + '.video'"
                        v-model="slider.video"
                        :state="errors && errors['sliders.' + index + '.video'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['sliders.' + index + '.video']">
                        {{ errors['sliders.' + index + '.video'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-if="slider.type === 'photo'"
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.photo.label')"
                      :state="errors && errors['sliders.' + index + '.photo'] ? false : null"
                    >
                      <!-- media -->
                      <b-media no-body>
                        <b-media-aside>
                          <b-link>
                            <b-img
                              ref="previewEl"
                              rounded
                              :src="$options.filters.mediaUrl(slider, 'photo')"
                              height="80"
                            />
                          </b-link>
                          <!--/ avatar -->
                        </b-media-aside>

                        <b-media-body class="mt-75 ml-75">
                          <InitFileManager
                            field="photo"
                            type="page_slider_photo"
                            :multiple="false"
                            @fm-selected="selectedSliderPhoto($event, index)"
                          />

                          <!-- reset -->
                          <b-button
                            v-if="slider.photo"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-secondary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="slider.photo = null"
                          >
                            {{ $t('general.reset') }}
                          </b-button>
                          <!--/ reset -->

                        </b-media-body>
                      </b-media>
                      <!--/ media -->
                    </b-form-group>

                    <b-form-invalid-feedback v-if="errors && errors['sliders.' + index + '.photo']">
                      {{ errors['sliders.' + index + '.photo'][0] }}
                    </b-form-invalid-feedback>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeSlider(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>{{ $t('general.delete') }}</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addSlider"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.sliders"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.sliders[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'pages-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor, Quill } from 'vue-quill-editor'
import htmlEditButton from 'quill-html-edit-button'
import EditorMixin from '@/mixins/EditorMixin'

import {
  BTab,
  BTabs,
  BCard,
  BButton,
  BRow,
  BCol,
  BMedia,
  BMediaBody,
  BMediaAside,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BFormInvalidFeedback,
  BImg,
  BOverlay,
  BFormTextarea, BLink, BFormFile,
} from 'bootstrap-vue'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

import InitFileManager from '@/components/InitFileManager'
import StatusMixin from '@/mixins/StatusMixin'

Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
  directives: {
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BImg,
    BFormTextarea,
    BFormInvalidFeedback,
    vSelect,
    quillEditor,
    BOverlay,
    InitFileManager,
  },
  mixins: [StatusMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
      typeOptions: [
        { label: this.$t('general.pages.template_types.simple_page'), value: 'simple_page' },
        { label: this.$t('general.pages.template_types.about_us'), value: 'about_us' },
        { label: this.$t('general.pages.template_types.delivery_and_payment'), value: 'delivery_and_payment' },
        { label: this.$t('general.pages.template_types.guarantee'), value: 'guarantee' },
        { label: this.$t('general.pages.template_types.faq'), value: 'faq' },
        { label: this.$t('general.pages.template_types.list'), value: 'list' },
        { label: this.$t('general.pages.template_types.shops'), value: 'shops' },
        { label: this.$t('general.pages.template_types.html_sitemap'), value: 'html_sitemap' },
      ],
      sliderTypeOptions: [
        { label: this.$t('administration.page_slider_types.video'), value: 'video' },
        { label: this.$t('administration.page_slider_types.photo'), value: 'photo' },
      ],
      extensionOptions: [],
      attributesOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    await this.$http.get('/api/attributes', { params: { status: 'enabled', per_page: 100 } })
      .then(response => {
        this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.item = await this.transformData()
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    selectedPhoto(data) {
      this.item.photo = data
    },
    selectedSliderPhoto(data, index) {
      this.item.sliders[index].photo = data
    },
    async searchExtension(query) {
      await this.$http.get('/api/administration/extensions', {
        params: {
          query,
        },
      })
        .then(response => {
          this.extensionOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    addBrand() {
      const data = {
        title: {},
        href: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.href[language.code] = null
      })

      this.item.brands.push(data)
    },
    removeBrand(index) {
      this.item.brands.splice(index, 1)
    },
    addInformer() {
      const data = {
        title: {},
        value: {},
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.value[language.code] = null
      })

      this.item.informers.push(data)
    },
    removeInformer(index) {
      this.item.informers.splice(index, 1)
    },
    addSlider() {
      const data = {
        type: 'video',
        video: null,
        photo: null,
      }

      this.item.sliders.push(data)
    },
    removeSlider(index) {
      this.item.sliders.splice(index, 1)
    },
    addFaq() {
      const data = {
        group: {
          title: {},
        },
        items: [
          {
            answer: {},
            question: {},
            avatar: null,
          },
        ],
      }

      _.each(this.languages, language => {
        data.group.title[language.code] = null
        data.items[0].answer[language.code] = null
        data.items[0].question[language.code] = null
      })

      this.item.faq.push(data)
    },
    removeFaq(index) {
      this.item.faq.splice(index, 1)
    },
    addFaqItem(faqIndex) {
      const data = {
        answer: {},
        question: {},
        avatar: null,
      }

      _.each(this.languages, language => {
        data.answer[language.code] = null
        data.question[language.code] = null
      })

      this.item.faq[faqIndex].items.push(data)
    },
    removeFaqItem(faqIndex, index) {
      this.item.faq[faqIndex].items.splice(index, 1)
    },
    changeType() {
      this.item.brands = []
      this.item.informers = []
      this.item.sliders = []
      this.item.faq = []
      this.item.photo = null
      this.item.attribute_id = null
    },
    changeSliderType(type, index) {
      this.item.sliders[index].type = type
      this.item.sliders[index].video = null
      this.item.sliders[index].photo = null
    },
    selectedFaqItemAvatar(data, index, itemIndex) {
      this.item.faq[index].items[itemIndex].avatar = data
    },
    async onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        template_type: this.item.template_type,
        slug: this.item.slug,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        indexing_status: this.item.indexing_status,
        top_extension_id: this.item.top_extension_id,
        bottom_extension_id: this.item.bottom_extension_id,
        attribute_id: this.item.attribute_id,
        brands: this.item.brands,
        informers: this.item.informers,
        sliders: this.item.sliders,
        faq: this.item.faq,
        photo: this.item.photo,
      }

      this.$http.post('/api/administration/pages', data)
        .then(() => {
          router.replace({ name: 'pages-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      const data = {
        template_type: 'simple_page',
        title: {},
        description: {},
        slug: null,
        photo: null,
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        indexing_status: 'enabled',
        top_extension_id: null,
        bottom_extension_id: null,
        attribute_id: null,
        brands: [],
        informers: [],
        sliders: [],
        faq: [],
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
        data.meta_description[language.code] = null
        data.meta_h1[language.code] = null
        data.meta_keywords[language.code] = null
        data.meta_title[language.code] = null
        data.canonical[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
